import actionTypes from '../actions/actionTypes';

export default function alertReducer(state = {}, action) {
  let newState = null;

  switch (action.type) {
    case actionTypes.SHOW_ALERT:
      newState = {
        ...state, isVisible: true, message: action.alertData.message, type: action.alertData.type,
      };
      break;

    case actionTypes.DISABLE_ALERT:
      newState = {
        ...state, isVisible: false,
      };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
}

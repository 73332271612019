/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProtectedRoute({
  path, component: Compo, exact, ...rest
}) {
  const access = useSelector(({ authReducer }) => authReducer?.access);
  return (
    <Route
      to={path}
      exact={exact}
      {...rest}
      render={(props) => (access
        ? <Compo {...props} />
        : <Redirect to="/" />)}
    />
  );
}

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.any.isRequired,
};

ProtectedRoute.defaultProps = {
  exact: false,
};

export default ProtectedRoute;

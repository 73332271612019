export default {
  INFLUENCER: {
    slug: 'influencer',
    id: 1,
  },
  COMPANY: {
    slug: 'company',
    id: 2,
  },
};

/* eslint-disable no-unused-vars */
import actionTypes from '../actions/actionTypes';

export default function alertReducer(state = {}, action) {
  let newState = null;

  switch (action.type) {
    default:
      newState = state;
      break;
  }

  return newState;
}

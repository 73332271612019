import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function NavigationItem({
  icon, to, text, notificationNumber,
}) {
  return (
    <NavLink
      to={to}
      activeStyle={{ background: '#5A67D8', color: 'white', transform: 'translateX(0.75rem)' }}
      className="nav__item py-3 px-2 mb-2 w-full text-center rounded-xl font-semibold flex flex-row justify-start items-center lg:w-64 lg:px-4"
    >
      <img src={icon} alt="profile icon" className="mr-2 w-4" />
      <p className="w-36 truncate overflow-ellipsis text-left lg:w-44">
        {text}
      </p>
      <div className="flex-1" />
      {notificationNumber !== 0 && (
      <div className="rounded-full text-sideMenuBackground bg-notificationBackground w-6 h-6 text-sm flex justify-center items-center">
        {
          notificationNumber <= 9
            ? notificationNumber
            : '+9'
        }
      </div>
      )}
    </NavLink>
  );
}

NavigationItem.propTypes = {
  icon: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  notificationNumber: PropTypes.number,
};

NavigationItem.defaultProps = {
  notificationNumber: 0,
};

export default NavigationItem;

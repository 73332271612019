import React from 'react';
import PropTypes from 'prop-types';

function TagCard({ tagName, isSmall, noMargin }) {
  return (
    <div className={`flex ${isSmall ? 'w-16 py-1 text-xs' : 'w-24 py-1 px-4 text-sm'} ${!noMargin && 'mb-1 mr-2'} 
    justify-center items-center bg-mainButton rounded-full text-white font-bold transform cursor-pointer hover:text-black hover:scale-105 duration-300`}
    >
      {tagName}

    </div>
  );
}

TagCard.propTypes = {
  tagName: PropTypes.string.isRequired,
  isSmall: PropTypes.bool,
  noMargin: PropTypes.bool,
};

TagCard.defaultProps = {
  isSmall: false,
  noMargin: false,
};
export default TagCard;

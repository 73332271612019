import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from '../../../plugin/axios';

import apiEndpoints from '../../../constants/apiEndpoints';
import styleConstants from '../../../constants/styleConstants';
import userTypes from '../../../constants/userTypes';

// Components
import { login } from '../../../redux/actions/authActions';
import Header from '../../../components/Header/Header';
import CompanyForm from './components/CompanyForm/CompanyForm';
import InfluencerForm from './components/InfluencerForm/InfluencerForm';
import {
  HTTP_STATUS_CODE, API_PATH, LOGIN_REDIRECT_PATH,
} from '../../../constants';

function SignupForm() {
  const dispatch = useDispatch();
  const {
    global: {
      PAGE_MARGIN_TOP,
      DESKTOP_PAGE_MARGIN_TOP,
      PAGE_PADDING,
      DESKTOP_PAGE_PADDING,
    },
  } = styleConstants;

  const { signupType } = useParams();
  const [warningMsg, setWarningMsg] = React.useState('');

  React.useEffect(() => {
    /*
    * Check session validation.
    * If valid then go to the LOGIN REDIRECT PATH
    */
    axios.get(API_PATH.VERIFY_SESSION).then((response) => {
      const validSessionAndJWT = response.status !== HTTP_STATUS_CODE.FORBIDDEN;
      // TODO make a flag. do not use node_env
      if (validSessionAndJWT && process.env.NODE_ENV === 'production') {
        // Session is not expired. Login is not needed. Redirect to the root page.
        window.location.replace(LOGIN_REDIRECT_PATH);
      }
    }).catch((e) => {
      console.log(e);
      console.log('ERROR');
    });
  }, []);

  const handleSignup = (data) => {
    console.log(data);
    axios.post(apiEndpoints.SIGNUP, data)
      .then((response) => {
        const { email } = response.data;
        console.log('SIGNUP RESPONSE');
        console.log(response);
        console.log(data);
        dispatch(login(email, data.password, data.role, true));
        // history.replace(PATH.LOGIN);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response && error.response.status === HTTP_STATUS_CODE.HTTP_400_BAD_REQUEST) {
          Object.keys(error.response.data).forEach((key) => {
            console.log(error.response.data[key]);
            console.log(typeof error.response.data[key]);
            if (typeof error.response.data[key] === 'string') {
              setWarningMsg(error.response.data[key]);
            } else if (typeof error.response.data[key][0] === 'string') {
              setWarningMsg(error.response.data[key][0]);
            } else {
              setWarningMsg('Si è verificato un errore. Contattaci se dovesse ripetersi.');
            }
          });
        } else {
          setWarningMsg('Si è verificato un errore. Contattaci se dovesse ripetersi.');
        }
      });
  };

  return (
    <div id="signup-step2" className={`${PAGE_MARGIN_TOP} ${DESKTOP_PAGE_MARGIN_TOP} ${PAGE_PADDING} ${DESKTOP_PAGE_PADDING} bg-background `}>
      <Header />
      <div id="signup-step2-container m-auto">
        <h1 className="text-3xl text-center font-semibold pb-6">
          {signupType === userTypes.INFLUENCER.slug
            ? 'Benvenuto influencer!'
            : 'Benvenuto!'}
        </h1>
        <p className="mb-8 text-center">Crea il tuo account per entrare a far parte della nostra community</p>
        {
          signupType === userTypes.INFLUENCER.slug
            ? (
              <InfluencerForm
                handleSignup={handleSignup}
                warningMsg={warningMsg}
                setWarningMsg={setWarningMsg}
              />
            )
            : (
              <CompanyForm
                handleSignup={handleSignup}
                warningMsg={warningMsg}
                setWarningMsg={setWarningMsg}
              />
            )
        }
      </div>
    </div>
  );
}

export default SignupForm;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import PropTypes from 'prop-types';

import './CompanyForm.css';

import axios from '../../../../../plugin/axios';
import apiEndpoints from '../../../../../constants/apiEndpoints';
// Constants
import formWarningMessages from '../../../../../constants/formWarningMessages';

// Components
import SignupFormInput from '../SignupFormInput/SignupFormInput';
import MainButton from '../../../../../components/MainButton/MainButton';

import { ROLES } from '../../../../../constants';

const initialFormValues = {
  city: null,
  company: null,
  'confirm-password': null,
  email: null,
  lastname: null,
  name: null,
  password: null,
  phone: null,
  piva: null,
  type: null,
};

const animatedComponents = makeAnimated();

const getCompanySignupData = (data) => ({
  username: data.email.toLowerCase(),
  email: data.email.toLowerCase(),
  password: data.password,
  role: ROLES.COMPANY.id,
  first_name: data.name,
  last_name: data.lastname,
  agency_profile: {
    piva: data.piva,
    city: data.city,
    company_name: data.company,
    phone: data.phone,
    type: data.type,
  },
});

const CompanyForm = ({ handleSignup, setWarningMsg, warningMsg }) => {
  const [formValues, setFormValues] = useState(initialFormValues);
  const [inputWrongValues, setInputWrongValues] = useState({});
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [types, setTypes] = React.useState([]);

  const minPasswordLength = 8;

  function handleInputChange({ target }) {
    setFormValues({ ...formValues, [target.name]: target.value });
    target.classList.remove('emptyField');
  }

  function handleCompanyFormClick() {
    let isFormValid = true;
    const wrongValues = {};

    console.log(formValues);
    Object.entries(formValues).forEach(([key, value]) => {
      if (!value) {
        isFormValid = false;
        setWarningMsg(formWarningMessages.EMPTY_FIELDS);
        wrongValues[key] = true;
      }
    });
    setInputWrongValues(wrongValues);

    if (formValues.password !== formValues['confirm-password']) {
      isFormValid = false;
      setWarningMsg(formWarningMessages.PASSWORDS_DONT_MATCH);
    }

    if (formValues.password?.length < minPasswordLength) {
      isFormValid = false;
      setWarningMsg(formWarningMessages.MIN_PASSWORD_LENGTH);
    }

    if (!isCheckboxChecked) {
      isFormValid = false;
      setWarningMsg(formWarningMessages.NO_CONSENT);
    }

    if (isFormValid) {
      setWarningMsg('');
      const signupData = getCompanySignupData(formValues);
      handleSignup(signupData);
    }
  }

  const handleTypeChange = (values) => {
    setFormValues({ ...formValues, type: values.value });
  };

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      flexDirection: 'column',
      borderRadius: '1.5rem',
      marginBottom: '0.5rem',
      width: '18rem',
      maxWidth: '18rem',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '1.5rem',
      padding: '0.7rem',
      backgroundColor: 'transparent',
      borderColor: inputWrongValues.categories ? 'red' : 'gray',
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '10px',
      color: 'white',
      backgroundColor: '#5a67d8',
    }),
  };

  React.useEffect(() => {
    axios.get(apiEndpoints.COMPANY_TYPES).then((res) => {
      if (res.status >= 200) {
        setTypes(() => res.data.map((el) => ({
          label: el.name,
          value: el.slug,
        })));
      }
    });
  }, []);
  return (
    <form id="company-form" className="w-full flex flex-col justify-center items-center">
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="name" labelText="NOME" isIncorrect={inputWrongValues.name} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="lastname" labelText="COGNOME" isIncorrect={inputWrongValues.lastname} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="company" labelText="NOME DELL'AZIENDA" isIncorrect={inputWrongValues.company} />
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        onChange={handleTypeChange}
        styles={customSelectStyles}
        placeholder="TIPO"
        options={types}
      />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="city" labelText="CITTÀ" isIncorrect={inputWrongValues.city} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="phone" labelText="TELEFONO" isIncorrect={inputWrongValues.phone} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="piva" labelText="PIVA/CF" isIncorrect={inputWrongValues.piva} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} type="email" name="email" labelText="EMAIL" isIncorrect={inputWrongValues.email} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="password" labelText="PASSWORD" type="password" isIncorrect={inputWrongValues.password} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="confirm-password" labelText="CONFERMA PASSWORD" type="password" isIncorrect={inputWrongValues['confirm-password']} />

      <div className="mb-6">
        <input type="checkbox" id="consent" className="mr-2" onChange={({ target }) => { setIsCheckboxChecked(target.checked); }} />
        <label>
          <a href="/" className="text-inputBorder underline">Acconsento al trattamento dei dati</a>
        </label>
      </div>

      <span className="form__warning text-red-500 text-sm mb-4 h-4">{warningMsg}</span>
      <MainButton
        text="Crea un account"
        onClick={() => {
          handleCompanyFormClick();
        }}
      />
    </form>
  );
};

CompanyForm.propTypes = {
  handleSignup: PropTypes.func.isRequired,
  warningMsg: PropTypes.string.isRequired,
  setWarningMsg: PropTypes.func.isRequired,
};

export default CompanyForm;

import React from 'react';
import PropTypes from 'prop-types';

import globalStyles from '../../constants/styleConstants';
import './MainButton.css';

import loadingGif from '../../static/img/loading.gif';

function setTailwindAttributes(size) {
  const {
    mainButton: { big, normal, medium },
  } = globalStyles;

  let returnObject = {};
  switch (size) {
    case 'big':
      returnObject = {
        widthAttribute: big.WIDTH,
        verticalPaddingAttribute: big.VERTICAL_PADDING,
        fontSizeAttribute: big.FONT_SIZE,
        roundedAttribute: big.BORDER_RADIUS,
      };
      break;
    case 'normal':
      returnObject = {
        widthAttribute: normal.WIDTH,
        verticalPaddingAttribute: normal.VERTICAL_PADDING,
        fontSizeAttribute: normal.FONT_SIZE,
        roundedAttribute: normal.BORDER_RADIUS,
      };
      break;
    case 'medium':
      returnObject = {
        widthAttribute: medium.WIDTH,
        verticalPaddingAttribute: medium.VERTICAL_PADDING,
        fontSizeAttribute: medium.FONT_SIZE,
        roundedAttribute: medium.BORDER_RADIUS,
      };
      break;
    default:
      returnObject = {
        widthAttribute: normal.WIDTH,
        verticalPaddingAttribute: normal.VERTICAL_PADDING,
        fontSizeAttribute: normal.FONT_SIZE,
        roundedAttribute: normal.BORDER_RADIUS,
      };
  }

  return returnObject;
}

/*
  ### Code example ###

  - Normal button (no size prop needed, as default is "normal")
    <MainButton text="Click Me!" onClick={()=>{console.log('Clicked!')}} />

  - Medium button (size="medium" prop)
    <MainButton text="Click Me!" size="medium" onClick={()=>{console.log('Clicked!')}} />

  - Big button (size="big" prop)
    <MainButton text="Click Me!" size="big" onClick={()=>{console.log('Clicked!')}} />

  - Loading button (loading)
    <MainButton text="Login" loading/>
*/

function MainButton({
  text, onClick, size, loading,
}) {
  const {
    widthAttribute,
    verticalPaddingAttribute,
    fontSizeAttribute,
    roundedAttribute,
  } = setTailwindAttributes(size);

  const loadingContainer = (
    <div className="flex flex-row justify-center items-center">
      <img src={loadingGif} alt="loading giff" className="h-6 mr-1" />
      <span className={`${fontSizeAttribute}`}>Loading...</span>
    </div>
  );

  return (
    <button
      type="button"
      onClick={onClick}
      className={`main-button bg-mainButton text-white border-none ${verticalPaddingAttribute} ${widthAttribute} ${fontSizeAttribute} ${roundedAttribute} ${size === 'big' && 'shadow-bigButton'}`}
    >
      {loading ? loadingContainer : text}
    </button>
  );
}

MainButton.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

MainButton.defaultProps = {
  size: 'normal',
  loading: false,
  onClick: () => {},
};

export default MainButton;

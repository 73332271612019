import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Pagination from 'react-js-pagination';

// Components
import AppWrapper from '../AppWrapper/AppWrapper';
import InfluencerCard from './components/InfluencerCard/InfluencerCard';
import InfluencerSearch from '../../components/InfluencerSearch/InfluencerSearch';

function Influencers() {
  const influencers = useSelector(({ mainReducer }) => mainReducer.influencers);

  // eslint-disable-next-line no-unused-vars
  const [perPage, setPerPage] = useState(20);
  const [activePage, setActivePage] = useState(1);
  const [influencersPage, setInfluencersPage] = useState([]);

  useEffect(() => {
    if (influencers) {
      const indexOfLastInfluencer = activePage * perPage;
      const indexOfFirstInfluencer = indexOfLastInfluencer - perPage;
      const pageInfluencers = influencers
        .slice(indexOfFirstInfluencer, indexOfLastInfluencer);
      setInfluencersPage(pageInfluencers);
    }
  }, [influencers, activePage]);

  function handlePageChange(page) {
    setActivePage(page);
  }

  return (
    <AppWrapper>
      <section className="mb-8">
        <h1 className="text-2xl font-bold mb-4">Trova gli influencer giusti per te</h1>
        <p className="text-center lg:text-left lg:w-4/5 mb-8">
          Il tuo abbonamento ti permette di visualizzare la lista degli influencer
          nel territorio da te scelto e con le tue categorie, per una settimana.
        </p>
        <InfluencerSearch />
      </section>
      <div className="separator w-full h-separator bg-gray-300 mb-12" />
      <section className="influencers-section flex flex-row flex-wrap justify-center lg:justify-start">
        {influencersPage?.length && influencersPage.map((influencer) => (
          <InfluencerCard influencer={influencer} key={influencer.id} />
        ))}
      </section>
      <div className="influencers-pagination flex flex-row justify-center pb-8">
        {influencers.length > perPage && (
          <Pagination
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={influencers.length}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        )}
      </div>
    </AppWrapper>
  );
}

export default Influencers;

/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import actionTypes from '../actions/actionTypes';

export default function mainReducer(state = {}, action) {
  let newState = null;

  switch (action.type) {
    case actionTypes.SET_PLANS_LOADING:
      newState = { ...state, premiumPlansLoading: true };
      break;

    case actionTypes.LOAD_PREMIUM_PLANS:
      newState = { ...state, premiumPlansLoading: false, premiumPlans: action.premiumPlans };
      break;

    case actionTypes.SET_IS_INFLUENCER_FAVORITE:
      const newInfluencers = state.influencers.map((influencer) => {
        if (influencer.id === action.payload.influencerId) {
          influencer.isFavorite = action.payload.isFavorite;
        }
        return influencer;
      });
      newState = { ...state, influencers: newInfluencers };
      break;

    case actionTypes.UNFAV_WISHLIST_INFLUENCER:
      const newWishList = state.wishList.influencers.filter(
        (influencer) => (influencer.id !== action.payload.influencerId),
      );

      newState = {
        ...state,
        wishList:
        { uid: action.payload.wishListId, influencers: newWishList },
      };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
}

export default {
  LOGIN: '/auth/token/create/',
  SIGNUP: '/auth/users/',
  JWT_REFRESH: '/auth/token/refresh/',
  VERIFY_SESSION: '/auth/session/verify/',
  VERIFY_JWT: '/auth/token/verify/',
  INSIGHTS_REGISTRATIONS: '/insights/registrations/',
  INFLUENCERS_CATEGORIES: '/influencer/categories/',
  COMPANY_TYPES: '/agency/company/types/',
};

import React from 'react';
import PropTypes from 'prop-types';

import './KpiCard.css';

/*
  ### Notes ###
    - The image prop must be passed as a variable (import it in the parent component)
    - Style prop values:
        > white (white background blue border) [DEFAULT]
        > gray (gray background blue border)
        > dark (dark background no border)

  ### Code example ###

    ** In parent component **
    import kpiImage from '../assets/kpi-icon1.png'

    <KpiCard image={kpiImage} text="Influencer iscritti" value={122} />
    <KpiCard image={kpiImage} text="Influencer iscritti" value={122} style='white' />
    <KpiCard image={kpiImage} text="Influencer iscritti" value={122} style='dark' />
*/

function KpiCard({
  image, text, value, kpiStyle,
}) {
  function setOutterStyle(style) {
    return style !== 'dark'
      ? 'from-kpiGradientStart to-kpiGradientEnd'
      : 'from-kpiGradientStart_dark to-kpiGradientEnd_dark';
  }

  function setInnerStyle(style) {
    let returnStyle = '';
    switch (style) {
      case 'white':
        returnStyle = 'bg-kpiBg_white';
        break;
      case 'gray':
        returnStyle = 'bg-kpiBg_gray';
        break;
      case 'dark':
        returnStyle = 'bg-kpiBg_dark text-white';
        break;
      default:
        returnStyle = 'bg-kpiBg_white';
        break;
    }

    return returnStyle;
  }

  return (
    <div id="kpi-card" className={`items-center w-80 text-lg m-2 rounded-kpiOutter p-1 bg-gradient-to-r ${setOutterStyle(kpiStyle)}`}>
      <div id="kpi-card__container" className={`flex flex-row w-full rounded-kpiInner py-4 px-3 ${setInnerStyle(kpiStyle)}`}>
        <img src={image} alt="kpi-icon" className="w-18 mr-4" />
        <div id="kpi-card__content" className="flex flex-col">
          <p className="text-sm font-semibold text-gray-500">{text}</p>
          <p className="text-4xl font-semibold ">{value}</p>
        </div>
      </div>
    </div>
  );
}

KpiCard.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  kpiStyle: PropTypes.string,
};

KpiCard.defaultProps = {
  kpiStyle: 'white',
};

export default KpiCard;

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './MainAlert.css';

import alertSuccess from '../../static/img/alert-success.png';
import alertWarning from '../../static/img/alert-warning.png';
import alertError from '../../static/img/alert-error.png';

// Action creators
import { disableAlert } from '../../redux/actions/alertActions';
/*
    ## Types:
        - 0: Success
        - 1: Warning
        - 2: Error

    ## Code Example:

*/

function setIconByType(type) {
  let alertIcon = '';
  switch (type) {
    case 0:
      alertIcon = alertSuccess;
      break;
    case 1:
      alertIcon = alertWarning;
      break;
    case 2:
      alertIcon = alertError;
      break;
    default:
      alertIcon = '';
      break;
  }

  return alertIcon;
}

function setClassByType(type) {
  let typeClass = '';
  switch (type) {
    case 0:
      typeClass = 'alert-success';
      break;
    case 1:
      typeClass = 'alert-warning';
      break;
    case 2:
      typeClass = 'alert-error';
      break;
    default:
      typeClass = alertWarning;
      break;
  }

  return typeClass;
}

function MainAlert() {
  const dispatch = useDispatch();
  const { isVisible, message, type } = useSelector(({ alertReducer }) => alertReducer);
  useEffect(() => {
    setTimeout(() => {
      dispatch(disableAlert());
    }, 4000);
  }, [type, message, isVisible]);

  return (
    <div id="main-alert" className={`fixed top-2 right-2 lg:bottom-8 lg:right-16 px-4 py-2 rounded-xl w-80 lg:w-alert z-20 ${setClassByType(type)} ${isVisible ? 'grow-alert' : 'hidden-alert'}`}>
      <div className="main-alert-container flex flex-row justify-between items-center">
        <img src={setIconByType(type)} alt="alert icon" id="main-alert__icon" className="h-6 mx-4" />
        <p id="main-alert__text">{message}</p>
        <span className="font-bold cursor-pointer hover:text-white duration-300" onClick={() => { dispatch(disableAlert()); }}>x</span>
      </div>
    </div>
  );
}

export default MainAlert;

import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

// Custom protected route
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

// Componentes
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import Signup from './pages/SignUpComponents/Signup/Signup';
import SignupForm from './pages/SignUpComponents/SignupForm/SignupForm';
import PremiumPlans from './pages/PremiumPlans/PremiumPlans';
import Influencers from './pages/Influencers/Influencers';
import MainAlert from './components/MainAlert/MainAlert';
import Favorites from './pages/Favorites/Favorites';
import Stats from './pages/Stats/Stats';
import store from './redux/configureStore';
import { PATH } from './constants';

function App() {
  return (
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          {/*
          <ProtectedRoute path="/profile" component={Profile} />
          <ProtectedRoute path="/plans" component={PremiumPlans} />
          <ProtectedRoute path="/influencers" component={Influencers} />
          <ProtectedRoute path="/favorites" component={Favorites} />
          <ProtectedRoute path="/stats" component={Stats} />
          */}
          <Route path={PATH.SIGNUPFORM} component={SignupForm} />
          <Route path={PATH.SIGNUP} exact component={Signup} />
          <Route path={PATH.LOGIN} component={Login} />
        </Switch>
        <MainAlert />
      </Router>
    </Provider>
  );
}

export default App;

import actionTypes from './actionTypes';
import apiEndpoints from '../../constants/apiEndpoints';
import errorMessages from '../../constants/errorMessages';
import axios from '../../plugin/axios';
import { ROLES, PATH } from '../../constants';

function loginSuccess(userTokens) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    userTokens,
  };
}

function loginError(error) {
  return {
    type: actionTypes.LOGIN_ERROR,
    error,
  };
}

function setLoginLoading() {
  return {
    type: actionTypes.SET_LOGIN_LOADING,
  };
}

export function clearUserTokens() {
  localStorage.removeItem('userTokens');
  return {
    type: actionTypes.CLEAR_USER_TOKENS,
  };
}

export function setRegisterUserType(userType) {
  return {
    type: actionTypes.SET_REGISTER_USER_TYPE,
    userType,
  };
}

function setSignupLoading() {
  return {
    type: actionTypes.SET_SIGNUP_LOADING,
  };
}

function signupSuccess(userData) {
  return {
    type: actionTypes.SIGNUP_SUCCESS,
    userData,
  };
}

function signupError() {
  return {
    type: actionTypes.SIGNUP_ERROR,
    error: errorMessages.SIGNUP_ERROR,
  };
}

// eslint-disable-next-line no-unused-vars
export function login(email, password, userRoleID = null, signUp = false) {
  return async (dispatch) => {
    dispatch(setLoginLoading());
    try {
      // Disabled until we have the API
      const loginEndpoint = apiEndpoints.LOGIN;
      const body = {
        username: email,
        password,
      };
      const { data } = await axios.post(loginEndpoint, body);
      if (data.access) {
        localStorage.setItem('access', data.access);
        localStorage.setItem('refresh', data.refresh);
        dispatch(loginSuccess(data));
        if (signUp && userRoleID && userRoleID === ROLES.INFLUENCER.id) {
          // Go to the list of services
          window.location.replace(PATH.INFLUENCER_SIGNUP_REDIRECT_PATH);
        } else {
          window.location.replace(PATH.LOGIN_REDIRECT_PATH);
        }
      } else {
        dispatch(loginError('username o password non corretti'));
      }
    } catch (error) {
      dispatch(loginError('username o password non corretti'));
    }
  };
}

// eslint-disable-next-line no-unused-vars
export function signup(userData) {
  return async (dispatch) => {
    // dispatch(setSignupLoading());
    try {
      const res = await axios.post(apiEndpoints.SIGNUP, userData);
      console.log('SUCCESS');
      console.log(res);
      dispatch(signupSuccess(res.data));
    } catch (error) {
      dispatch(signupError());
    }
  };
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './SignupFormInput.css';

/*
  ### Code example ###

  - Text input --> No "type" prop needed
    <SignupFormInput onChange={() => {someFunction()}} name="name" labelText="NOME" />

  - Password input --> type="password" prop
    <SignupFormInput name="passwordName" labelText="Enter Password" type="password"/>

  - Date input --> type="date" prop
    <SignupFormInput name="passwordName" labelText="Enter Password" type="password"/>

  >> NOTE: onChange prop is optional.
*/

function SignupFormInput({
  name, labelText, type, onChange, isIncorrect, isDisabled, value,
}) {
  return (
    <div id="form__entry" className="flex flex-col jusitfy-center items-center w-72 -mb-2 lg:mr-0">
      <input type={type} id={name} name={name} placeholder={labelText} onChange={onChange} disabled={isDisabled} defaultValue={value} className={`form__input text-md w-72 p-2 font-semibold pt-6 bg-transparent ${!isDisabled && 'border'} border-solid border-gray-400 rounded-3xl ${type === 'date' && 'pl-14'} ${isIncorrect && 'emptyField'}`} />
      <label htmlFor={name} className="input__label font-semibold text-sm w-full flex items-center justify-center">
        {labelText}
      </label>
    </div>
  );
}

SignupFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  isIncorrect: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
};

SignupFormInput.defaultProps = {
  type: 'text',
  isIncorrect: false,
  isDisabled: false,
  value: '',
  onChange: () => {},
};

export default SignupFormInput;

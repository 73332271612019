import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from '../../plugin/axios';

import './Login.css';

// Style constants
import styleConstants from '../../constants/styleConstants';

// Icons
import kpiBrands from '../../static/img/kpi-brands.png';
import kpiInfluencers from '../../static/img/kpi-influencers.png';

// Action creators
import { login, clearUserTokens } from '../../redux/actions/authActions';

// Components
import Header from '../../components/Header/Header';
import MainButton from '../../components/MainButton/MainButton';
import KpiCard from '../../components/KpiCard/KpiCard';

import {
  HTTP_STATUS_CODE, PATH, API_PATH, LOGIN_REDIRECT_PATH,
} from '../../constants';

function Login({ dispatch }) {
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { loginLoading, loginError } = useSelector(({ authReducer }) => authReducer);

  const [influencersNumber, setInfluencersNumber] = React.useState(0);
  const [companiesNumber, setCompaniesNumber] = React.useState(0);
  const [hasErasedUserTokens, sethasErasedUserTokens] = useState(false);

  useEffect(() => {
    if (!hasErasedUserTokens) {
      dispatch(clearUserTokens());
      sethasErasedUserTokens(true);
    }
    /*
    * Check session validation.
    * If valid then go to the LOGIN REDIRECT PATH
    */
    axios.get(API_PATH.VERIFY_SESSION).then((response) => {
      const validSessionAndJWT = response.status !== HTTP_STATUS_CODE.FORBIDDEN;
      // TODO make a flag. do not use node_env
      if (!validSessionAndJWT) {
        localStorage.removeItem('refresh');
        localStorage.removeItem('access');
      }
      if (validSessionAndJWT && process.env.NODE_ENV === 'production') {
        // Session is not expired. Login is not needed. Redirect to the root page.
        window.location.replace(LOGIN_REDIRECT_PATH);
      }
    });
    axios.get(API_PATH.INSIGHTS_REGISTRATIONS).then((response) => {
      // handle success
      if (response.data) {
        if (response.data.companies) {
          setCompaniesNumber(response.data.companies);
        }
        if (response.data.influencers) {
          setInfluencersNumber(response.data.influencers);
        }
      }
    })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  }, []);

  function handleLoginClick() {
    dispatch(login(email, password));
  }
  function handleRegisterClick() {
    history.replace(PATH.SIGNUP);
  }

  const {
    global: {
      PAGE_MARGIN_TOP, DESKTOP_PAGE_MARGIN_TOP, PAGE_PADDING, DESKTOP_PAGE_PADDING,
    },
  } = styleConstants;

  const handleFormChange = (e) => {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  return (
    <div className={`${PAGE_MARGIN_TOP} ${DESKTOP_PAGE_MARGIN_TOP} ${PAGE_PADDING} ${DESKTOP_PAGE_PADDING} bg-background h-screen`}>
      <Header />
      <div id="home-container" className={`m-auto ${PAGE_PADDING} ${DESKTOP_PAGE_PADDING}`}>
        <div id="home__login" className="flex flex-col items-center lg:flex-row mb-28">
          <div id="login__separator" className="hidden lg:flex lg:w-1/3" />
          <form id="login__form" className="flex flex-col items-center lg:w-1/3">
            <h1 className="text-3xl text-center font-semibold mb-8">Accedi a Influencee</h1>
            <input name="email" onChange={handleFormChange} type="text" placeholder="Email" className="w-52 p-3 bg-background border border-solid border-gray-400 rounded-2xl mb-3" />
            <input name="password" onChange={handleFormChange} type="password" placeholder="Password" className="w-52 p-3 bg-background border border-solid border-gray-400 rounded-2xl mb-2" />
            <span className="text-sm text-red-400 mb-12 h-4">
              {loginError}
            </span>
            <MainButton text="Login" onClick={handleLoginClick} loading={loginLoading} />
            <a className="mt-4 text-mainButton underline" href="/accounts/password/reset/">Ho dimenticato la password</a>
          </form>
          <div id="login__kpi" className="flex flex-col items-end lg:w-1/3 mt-12">
            <KpiCard image={kpiBrands} text="BRAND ISCRITTI" value={companiesNumber} kpiStyle="gray" />
          </div>
        </div>

        <div id="home__register" className="flex flex-col items-center">
          <div id="register__separator" className="hidden lg:flex flex-grow" />
          <div id="register__main" className="lg:w-1/2 flex flex-col items-center">
            <h2 className="text-2xl text-center font-semibold mb-8">Non sei ancora iscritto a Influencee?</h2>
            <MainButton text="Non ho un account" onClick={handleRegisterClick} />
          </div>
          <div id="register__separator" className="hidden lg:flex flex-grow" />
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  dispatch: PropTypes.any.isRequired,
};

export default connect()(Login);

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './AppWrapper.css';

// Constants
import styleConstants from '../../constants/styleConstants';

// Icons
import burger from '../../static/img/burger.png';

// Components
import SideMenu from './components/SideMenu/SideMenu';

function AppWrapper({ children }) {
  const {
    global: {
      APP_WRAPPER_PADDING, APP_WRAPPER_MARGIN_TOP,
    },
  } = styleConstants;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div id="app-wrapper" className="bg-white w-screen h-screen flex flex-row bg-white lg:overflow-y-auto">
      <SideMenu isOpen={isMenuOpen} />
      <div
        id="menu-opacity-screen"
        className={`top-0 bottom-0 w-screen fixed transform ${!isMenuOpen && '-translate-x-full'} lg:hidden`}
        onClick={() => { setIsMenuOpen(!isMenuOpen); }}
      />
      <div id="app-wapper__header" className={`flex flex-row items-center h-16 fixed top-0 left-0 right-0 px-6 transform z-40 ${isMenuOpen && 'translate-x-48'} lg:hidden`}>
        <img src={burger} alt="burger icon" id="burger-icon" className={`h-4 ${isMenuOpen && 'transform -translate-x-4'}`} onClick={() => { setIsMenuOpen(!isMenuOpen); }} />
      </div>
      <div id="app-wrapper-container" className={`w-full lg:ml-80 ${APP_WRAPPER_PADDING} ${APP_WRAPPER_MARGIN_TOP}`}>
        {children}
      </div>
    </div>
  );
}

AppWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AppWrapper;

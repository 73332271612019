import actionTypes from '../actions/actionTypes';

export const initialState = {
  access: localStorage.getItem('access', null),
  refresh: localStorage.getItem('refresh', null),
  loginLoading: false,
  loginError: false,
};

export default function authReducer(state = {}, action) {
  let newState = {};

  switch (action.type) {
    case actionTypes.SET_LOGIN_LOADING:
      newState = { ...state, loginLoading: true };
      break;

    case actionTypes.LOGIN_SUCCESS:
      newState = {
        ...state,
        loginLoading: false,
        loginError: '',
        access: action.userTokens.access,
        refresh: action.userTokens.refresh,
      };
      break;

    case actionTypes.LOGIN_ERROR:
      newState = { ...state, loginLoading: false, loginError: action.error };
      break;

    case actionTypes.CLEAR_USER_TOKENS:
      newState = { ...state, access: null, refresh: null };
      break;

    case actionTypes.SET_REGISTER_USER_TYPE:
      newState = { ...state, signupType: action.userType };
      break;

    case actionTypes.SET_SIGNUP_LOADING:
      newState = { ...state, signupLoading: true };
      break;

    case actionTypes.SIGNUP_SUCCESS:
      newState = {
        ...state,
        signupLoading: false,
        userTokens: action.userData.userTokens,
        user: action.userData.user,
      };
      break;

    case actionTypes.SIGNUP_ERROR:
      newState = { ...state, signupLoading: false, signupError: action.error };
      break;

    default:
      newState = state;
      break;
  }

  return newState;
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../../static/img/logo.png';

function Header() {
  const history = useHistory();

  function handleLogoClick() {
    history.replace('/');
  }
  return (
    <header id="header" className="absolute top-0 left-0 right-0 z-10 py-4 px-4 bg-background lg:py-8 lg:px-32 ">
      <img src={logo} alt="influenceefe logo" className="w-20 lg:w-24" onClick={handleLogoClick} />
    </header>
  );
}

export default Header;

export default {
  SET_LOGIN_LOADING: 'SET_LOGIN_LOADING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  CLEAR_USER_TOKENS: 'CLEAR_USER_TOKENS',
  SET_REGISTER_USER_TYPE: 'SET_REGISTER_USER_TYPE',
  SET_SIGNUP_LOADING: 'SET_SIGNUP_LOADING',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  SHOW_ALERT: 'SHOW_ALERT',
  DISABLE_ALERT: 'DISABLE_ALERT',
  SET_PLANS_LOADING: 'SET_PLANS_LOADING',
  LOAD_PREMIUM_PLANS: 'LOAD_PREMIUM_PLANS',
  LOAD_PREMIUM_PLANS_ERROR: 'LOAD_PREMIUM_PLANS_ERROR',
  SET_IS_INFLUENCER_FAVORITE: 'SET_IS_INFLUENCER_FAVORITE',
  UNFAV_WISHLIST_INFLUENCER: 'UNFAV_WISHLIST_INFLUENCER',
};

import actionTypes from './actionTypes';

export function showAlert(message, type) {
  return {
    type: actionTypes.SHOW_ALERT,
    alertData: {
      message,
      type,
    },
  };
}

export function disableAlert() {
  return {
    type: actionTypes.DISABLE_ALERT,
  };
}

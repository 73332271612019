/* eslint no-param-reassign: ["error", { "props": false }] */

import axios from 'axios';

let baseURL = 'http://localhost:8000';
if (process.env.REACT_APP_ENV === 'production') {
  baseURL = 'https://app.influencee.it';
} else if (process.env.REACT_APP_ENV === 'stage') {
  baseURL = 'https://stage.influencee.it';
}

const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // eslint-disable-next-line
  console.log('INTERCEPT OK');
  console.log(response);
  return response;
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // eslint-disable-next-line
  if (error.response.status == 403) {
    localStorage.removeItem('refresh');
    localStorage.removeItem('access');
  }
  return Promise.reject(error);
});
// Obtain the fresh token each time the function is called
function getAccessToken() {
  return localStorage.getItem('access');
}

function getRefreshToken() {
  return localStorage.getItem('refresh');
}

// Use interceptor to inject the token to requests
instance.interceptors.request.use((request) => {
  if (getAccessToken()) {
    request.headers.Authorization = `Bearer ${getAccessToken()}`;
  }
  return request;
});

// Function that will be called to refresh authorization
/*
const refreshAuthLogic = (failedRequest) => instance.post(API_PATH.JWT_REFRESH, {
  refresh: getRefreshToken(),
}).then((tokenRefreshResponse) => {
  console.log('tokenRefreshResponse');
  console.log(tokenRefreshResponse);
  localStorage.setItem('access', tokenRefreshResponse.data.access);
  localStorage.setItem('refresh', tokenRefreshResponse.data.refresh);
  failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access}`
  return Promise.resolve();
}).catch((err) => {
  console.log('err refresh');
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  return Promise.reject(err);
});

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(instance, refreshAuthLogic);
*/

export default instance;

import React from 'react';
import PropTypes from 'prop-types';

import './CardButton.css';

/*
  CODE EXAMPLE:
  - For a white button (prop isWhite):
     <CardButton text="A white button" isWhite />

  - For a normal (purple) button:
    CardButton text="A purple button" />

*/

function CardButton({ text, isWhite }) {
  return (
    <div className="flex flex-row items-center justify-center w-36 p-0.5 rounded-full bg-gradient-to-br from-cardButtonGradientStart to-cardButtonGradientEnd card-button-class">
      <button id="card-button" type="button" className={`w-full h-full py-3 rounded-full text-white font-semibold ${isWhite && 'bg-white text-mainButton'}`}>{text}</button>
    </div>
  );
}

CardButton.propTypes = {
  text: PropTypes.string.isRequired,
  isWhite: PropTypes.bool,
};

CardButton.defaultProps = {
  isWhite: false,
};

export default CardButton;

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { initialState as authInitState } from './reducers/authReducer';

const initialState = {
  authReducer: authInitState,
  mainReducer: {},
  alertReducer: {},
  chartReducer: {},
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk)),
);

import React from 'react';
import { useHistory } from 'react-router-dom';

// Constants
import styleConstants from '../../../constants/styleConstants';
import userTypes from '../../../constants/userTypes';

// Components
import Header from '../../../components/Header/Header';
import MainButton from '../../../components/MainButton/MainButton';

import { PATH } from '../../../constants';

function Signup() {
  const history = useHistory();
  const {
    global: {
      PAGE_MARGIN_TOP,
      DESKTOP_PAGE_MARGIN_TOP,
      PAGE_PADDING,
      DESKTOP_PAGE_PADDING,
    },
  } = styleConstants;

  function handleOnClick(registerType) {
    history.replace(PATH.SIGNUPFORM.replace(':signupType', registerType));
  }

  return (
    <div
      id="signup-step1"
      className={`${PAGE_MARGIN_TOP} ${DESKTOP_PAGE_MARGIN_TOP} ${PAGE_PADDING} ${DESKTOP_PAGE_PADDING} bg-background h-screen`}
    >
      <Header />
      <div
        id="signup-step1-container"
        className="flex flex-col items-center lg:mt-20 lg:max-w-screen-lg m-auto pt-6"
      >
        <h1 className="text-3xl text-center font-semibold mb-6">
          Ottima scelta!
        </h1>

        <p className="text-center w-11/12">
          Influencee non è solo una piattaforma di ricerca di influencer per
          aziende, grazie a Influencee sarai in grado di ottenere oltre il 70%
          di visibilità da parte di aziende che hanno bisogno di visibilità.
          Dall’altra parte le aziende potranno creare e gestire le campagne
          marketing con una semplicità mai vista finora! Cosa aspetti? Crea un
          account gratuito e prova le sue funzionalità!
        </p>

        <div id="step1-buttons" className="flex flex-col lg:flex-row mt-12 lg:mt-20">
          <MainButton text="Sono un influencer" size="big" onClick={() => { handleOnClick(userTypes.INFLUENCER.slug); }} />
          <div className="mb-6 lg:mb-0 lg:mr-8" />
          <MainButton text="Cerco un influencer" size="big" onClick={() => { handleOnClick(userTypes.COMPANY.slug); }} />
        </div>
      </div>
    </div>
  );
}

export default Signup;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

// Constants
import formWarningMessages from '../../../../../constants/formWarningMessages';

// Action creators
import { login } from '../../../../../redux/actions/authActions';

// Components
import SignupFormInput from '../SignupFormInput/SignupFormInput';
import MainButton from '../../../../../components/MainButton/MainButton';
import axios from '../../../../../plugin/axios';
import apiEndpoints from '../../../../../constants/apiEndpoints';
import { ROLES, HTTP_STATUS_CODE, PATH } from '../../../../../constants';

const initialFormValues = {
  birthdate: '',
  city: '',
  'confirm-password': '',
  email: '',
  lastname: '',
  name: '',
  password: '',
  categories: [],
  ig_username: '',
  followers: 0,
  engagement_rate: 0,
};

const animatedComponents = makeAnimated();

const getInfluencerSignupData = (data) => ({
  email: data.email.toLowerCase(),
  password: data.password,
  role: ROLES.INFLUENCER.id,
  first_name: data.name,
  last_name: data.lastname,
  influencer_profile: {
    city: data.city,
    birthdate: data.birthdate,
    categories: data.categories,
  },
  ig_username: data.ig_username,
  followers: data.followers,
  engagement_rate: data.engagement_rate,
});

const InfluencerForm = ({ handleSignup, setWarningMsg, warningMsg }) => {
  const { signupLoading } = useSelector((authReducer) => authReducer);
  const history = useHistory();

  const [formValues, setFormValues] = useState(initialFormValues);
  const [inputWrongValues, setInputWrongValues] = useState({});
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [categories, setCategories] = React.useState([]);

  const minPasswordLength = 8;

  function handleInputChange({ target }) {
    setFormValues((current) => ({ ...current, [target.name]: target.value }));
    target.classList.remove('emptyField');
  }

  function handleInfluencerFormClick() {
    console.log(formValues);
    let isFormValid = true;
    const wrongValues = {};
    Object.keys(formValues).forEach((key) => {
      if (Array.isArray(formValues[key]) && formValues[key].length === 0) {
        isFormValid = false;
        setWarningMsg(formWarningMessages.EMPTY_FIELDS);
        wrongValues[key] = true;
      } else if (!formValues[key]) {
        isFormValid = false;
        setWarningMsg(formWarningMessages.EMPTY_FIELDS);
        wrongValues[key] = true;
      }
    });
    setInputWrongValues(wrongValues);

    if (formValues.password !== formValues['confirm-password']) {
      isFormValid = false;
      setWarningMsg(formWarningMessages.PASSWORDS_DONT_MATCH);
    }

    if (formValues.password?.length < minPasswordLength) {
      isFormValid = false;
      setWarningMsg(formWarningMessages.MIN_PASSWORD_LENGTH);
    }

    if (!isCheckboxChecked) {
      isFormValid = false;
      setWarningMsg(formWarningMessages.NO_CONSENT);
    }

    if (isFormValid) {
      setWarningMsg('');
      const signupData = getInfluencerSignupData(formValues);
      handleSignup(signupData);
    }
  }

  const handleCategoryChange = (values) => {
    const newCategories = values.map((el) => ({
      name: el.label,
      slug: el.value,
    }));
    setFormValues({ ...formValues, categories: newCategories });
  };

  React.useEffect(() => {
    axios.get(apiEndpoints.INFLUENCERS_CATEGORIES).then((res) => {
      if (res.status >= 200) {
        setCategories(() => res.data.map((el) => ({
          label: el.name,
          value: el.slug,
        })));
      }
    });
  }, []);

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      flexDirection: 'column',
      borderRadius: '1.5rem',
      marginBottom: '0.5rem',
      width: '18rem',
      maxWidth: '18rem',
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '1.5rem',
      padding: '0.7rem',
      backgroundColor: 'transparent',
      borderColor: inputWrongValues.categories ? 'red' : 'gray',
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '10px',
      color: 'white',
      backgroundColor: '#5a67d8',
    }),
  };
  return (
    <form id="influencer-form" className="w-full flex flex-col justify-center items-center">
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="name" labelText="NOME" isIncorrect={inputWrongValues.name} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="lastname" labelText="COGNOME" isIncorrect={inputWrongValues.lastname} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="birthdate" labelText="DATA DI NASCITA" type="date" isIncorrect={inputWrongValues.birthdate} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="city" labelText="CITTÀ" isIncorrect={inputWrongValues.city} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="ig_username" labelText="INSTAGRAM USERNAME" isIncorrect={inputWrongValues.ig_username} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="followers" labelText="INSTAGRAM FOLLOWERS" isIncorrect={inputWrongValues.followers} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="engagement_rate" labelText="INSTAGRAM ENGAGEMENT RATE" isIncorrect={inputWrongValues.engagement_rate} />
      <Select
        closeMenuOnSelect={false}
        components={animatedComponents}
        onChange={handleCategoryChange}
        isMulti
        styles={customSelectStyles}
        placeholder="CATEGORIA"
        options={categories}
      />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="email" labelText="EMAIL" isIncorrect={inputWrongValues.email} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="password" labelText="PASSWORD" type="password" isIncorrect={inputWrongValues.password} />
      <SignupFormInput onChange={(event) => { handleInputChange(event); }} name="confirm-password" labelText="CONFERMA PASSWORD" type="password" isIncorrect={inputWrongValues['confirm-password']} />
      <div className="mb-6">
        <input type="checkbox" id="consent" className="mr-2" onChange={({ target }) => { setIsCheckboxChecked(target.checked); }} />
        <label>
          <a href="/" className="text-inputBorder underline">Acconsento al trattamento dei dati</a>
        </label>
      </div>
      <span className="form__warning text-red-500 text-sm mb-4 h-4">{warningMsg}</span>
      <MainButton text="Crea un account" loading={signupLoading} onClick={handleInfluencerFormClick} />
    </form>
  );
};

export default InfluencerForm;

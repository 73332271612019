export default {
  global: {
    PAGE_MARGIN_TOP: 'mt-16',
    PAGE_PADDING: 'p-4',
    DESKTOP_PAGE_MARGIN_TOP: 'lg:mt-12',
    DESKTOP_PAGE_PADDING: 'lg:p-16',
    APP_WRAPPER_PADDING: 'p-6',
    APP_WRAPPER_MARGIN_TOP: 'mt-10',
    DESKTOP_APP_WRAPPER_PADDING: 'p-4',
    DESKTOP_APP_WRAPPER_MARGIN_TOP: 'mt-16',

  },
  mainButton: {
    big: {
      PROP_KEY_NAME: 'big',
      WIDTH: 'w-80',
      VERTICAL_PADDING: 'py-8',
      FONT_SIZE: 'text-xl',
      BORDER_RADIUS: 'rounded-buttonBig',
    },
    normal: {
      PROP_KEY_NAME: 'normal',
      WIDTH: 'w-48',
      VERTICAL_PADDING: 'py-3',
      FONT_SIZE: 'text-base',
      BORDER_RADIUS: 'rounded-2xl',
    },
    medium: {
      PROP_KEY_NAME: 'normal',
      WIDTH: 'w-60',
      VERTICAL_PADDING: 'py-4',
      FONT_SIZE: 'text-base',
      BORDER_RADIUS: 'rounded-2xl',
    },
  },
  premiumPlans: {
    categoria: {
      NAME: 'Piano Categoria',
      PromiseRejectionEvent: '€1.000',
      PAY_EVERY: 'AL MESE',
      DESCRIPTION: 'Lista completa degli influencer per le categorie scelte',
    },
    territorio: {
      NAME: 'Piano Territorio',
      PromiseRejectionEvent: '€5.000',
      PAY_EVERY: 'AL MESE',
      DESCRIPTION: 'Lista completa degli influencer per un solo territorio o provincia',
    },
    campagna: {
      NAME: 'Campagna singola',
      PromiseRejectionEvent: '€3.000',
      PAY_EVERY: 'PAGAMENTO UNICO',
      DESCRIPTION: 'Lista completa degli influencer per il territorio e le categorie scelte',
    },
  },
};

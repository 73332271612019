import React from 'react';
import { useSelector } from 'react-redux';

// Icons
import profileImage from '../../static/img/profile-img.png';
import conversationIcon from '../../static/img/conversation-icon.png';
import kpiPosts from '../../static/img/kpi-posts.png';

// Utils
import formatNumber from '../../utils/formatNumber';

// Components
import AppWrapper from '../AppWrapper/AppWrapper';
import NotificationsDropdown from '../../components/NotificationsDropdown/NotificationsDropdown';
import KpiCard from '../../components/KpiCard/KpiCard';
import PostCharts from './components/PostsCharts/PostsCharts';
import FollowersCharts from './components/FollowersCharts/FollowersCharts';
import InfluencerPosts from './components/InfluencerPosts/InfluencerPosts';

const notificationsMock = [{ label: 'Not1', value: 1 }, { label: 'Not2', value: 2 }];

function Stats() {
  const { stats, posts } = useSelector(({ mainReducer }) => mainReducer);
  const { user } = useSelector(({ authReducer }) => authReducer);

  return (
    <AppWrapper>
      <main className="w-full flex flex-col items-start lg:flex-row-reverse">
        <div className="flex items-start justify-center w-full mb-3.5 lg:w-1/3">
          <div className="flex-1 lg:hidden" />
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center lg:flex-row lg:mb-8">
              <div className="flex flex-row">
                <div className="w-1/2 flex justify-center mr-4 lg:w-24 lg:mr-8">
                  <img className="rounded-full lg:w-full" src={profileImage} alt="Profile" />
                </div>
                <div className="w-2/3 flex flex-row flex-start items-center lg:hidden">
                  <img className="h-4" src={conversationIcon} alt="Conversation icon" />
                  <div className="flex ml-3 w-14 justify-center items-center text-xs bg-mainButton rounded-md text-white">
                    <p>
                      {3}
                    </p>
                    <p>
                      &nbsp;
                      new
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex lg:flex-col p-6 w-full bg-sideMenuBackground shadow-xl rounded-2xl">
              <NotificationsDropdown placeholder="Request to be read" notifications={notificationsMock} notificationNumber={2} />
              <NotificationsDropdown placeholder="Requests" notifications={notificationsMock} notificationNumber={120} />
            </div>
          </div>
          <div className="flex-1 lg:hidden" />

        </div>
        <div className="w-full flex flex-col items-start lg:w-2/3 lg:mb-12">
          <div>
            <div className="w-full lg:mb-12">
              <h1 className="text-3xl text-center lg:text-left font-semibold h-8 mb-10 mt-5">
                @
                {user.name}
                {' '}
                stats
              </h1>
              <p className="hidden lg:flex">
                Disponible a scambio merce
              </p>
            </div>
            <div id="kpis" className="flex flex-wrap justify-evenly max-w-screen-md mb-8">
              <KpiCard image={kpiPosts} text="POST TOTALI" value={formatNumber(stats.totalPosts)} />
              <KpiCard image={kpiPosts} text="FOLLOWER TOTALI" value={formatNumber(stats.followers)} />
              <KpiCard image={kpiPosts} text="ENGAGEMENT" value={`${formatNumber(stats.engagement)} %`} />
              <KpiCard image={kpiPosts} text="ACCOUNT RAGGIUNTI" value={formatNumber(stats.reachedAccounts)} />
              <KpiCard image={kpiPosts} text="MEDIA DEI LIKE" value={formatNumber(stats.likesMean)} kpiStyle="dark" />
              <KpiCard image={kpiPosts} text="MEDIA DEI COMMENTI" value={formatNumber(stats.commentsMean)} kpiStyle="dark" />
            </div>
          </div>
          <div id="charts" className="flex flex-col items-center w-full">
            <PostCharts />
            <FollowersCharts />
          </div>
          <div id="posts" className="flex w-full">
            <InfluencerPosts posts={posts} />
          </div>
        </div>
      </main>
    </AppWrapper>
  );
}

export default Stats;

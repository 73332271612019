import { combineReducers } from 'redux';
import authReducer from './authReducer';
import mainReducer from './mainReducer';
import alertReducer from './alertReducer';
import chartReducer from './chartReducer';

const rootReducer = combineReducers({
  mainReducer, authReducer, alertReducer, chartReducer,
});

export default rootReducer;
